import { addTranslation, currentLanguage, currentLocale, translate } from "@telia/b2b-i18n";
import { Store } from "@telia/b2b-i18n/types/types";
import { fetchMicrocopy } from "@telia/b2x-microcopy";

export const t = (key: string): string => {
  return translate(key);
};

export const loadTranslations = async (readableIds: string[], fallback?: Store): Promise<void> => {
  const locale = currentLocale();
  const lang = currentLanguage();
  let translations: Record<string, string>;

  try {
    translations = await fetchMicrocopy(locale, readableIds);
  } catch {
    // do not propagate error up the call stack
  }

  return new Promise((resolve) => {
    if (translations && Object.keys(translations)?.length) {
      addTranslation(translations, locale);
    } else if (fallback) {
      addTranslation(fallback[lang], locale);
    }

    // resolve anyway and show children
    resolve();
  });
};
