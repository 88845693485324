import React from "react";
import { Store } from "@telia/b2b-i18n/types/types";

import { loadTranslations, t } from "./translation-service/translation-service";

type TranslationLoaderProps = {
  resourceSetKeys: string[];
  children: React.ReactNode;
  fallback?: Store;
};

class AsyncTranslationLoader extends React.Component<
  TranslationLoaderProps,
  { isLoading: boolean }
> {
  resourceSetKeys: string[];
  fallback?: Store;
  children: React.ReactNode;
  constructor(props: TranslationLoaderProps) {
    super(props);
    this.resourceSetKeys = props.resourceSetKeys;
    this.fallback = props.fallback;
    this.children = props.children;
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    loadTranslations(this.resourceSetKeys, this.fallback).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? null : this.children;
  }
}

const TranslationLoader = ({ resourceSetKeys, fallback, children }: TranslationLoaderProps) => {
  return (
    <AsyncTranslationLoader resourceSetKeys={resourceSetKeys} fallback={fallback}>
      {children}
    </AsyncTranslationLoader>
  );
};

export { t, TranslationLoader };
